<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%; width: 100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="width: 100%; margin: 0 auto;">
        <div style="text-align: left; margin-left: 10%; margin-top: 0.5rem;">
          <h1 class="title" style="" @click="()=>this.reload()">ESG投资</h1>
          <el-tooltip content="点击查看使用说明" effect="dark" placement="top">
            <i class="el-icon-question" style="color: #409EFF; margin-left: 2px;"
               @click="() => this.$refs.drawer1.visible = true"/>
          </el-tooltip>
          <el-button plain size="small" style="margin-left: 1rem;" type="info"
                     @click="() => this.$refs.drawer2.visible = true"><i
            class="el-icon-info"></i>&nbsp;策略说明
          </el-button>
        </div>
        <el-drawer
          ref="drawer2"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            ref="drawer1iframe"
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com/document/investDesc.html"
            style="width: 100%; border: hidden"
            title="ESG投资"
          >
          </iframe>
        </el-drawer>
        <el-row :gutter="0" style="width: 80%; margin: 0 auto; text-align: left; padding-top: 1rem">
          <el-col :span="18">

            <el-select
              v-model="selectModel"
              clearable collapse-tags filterable
              placeholder="请选择投资策略"
              size="medium"
              style="width: 18rem"
              @change="changeModel"
            >
              <el-option
                v-for="item in model"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <i class="el-icon-right" style="margin-right: 0.5rem; margin-left: 0.5rem"></i>
            <el-select
              v-model="selectIndustry"
              :disabled="this.selectModel !== 'esg'" clearable collapse-tags filterable
              multiple
              placeholder="请选择行业"
              size="medium"
              @change="changeSelect"
            >
              <el-checkbox v-model="isAllSelected" style="margin-left: 2%;" @change='selectAll'>全选</el-checkbox>
              <el-option
                v-for="item in industryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <i class="el-icon-right" style="margin-right: 0.5rem; margin-left: 0.5rem"></i>
            <el-select
              v-model="selectTextualIndex"
              :disabled="this.selectModel !== 'esg'" clearable collapse-tags filterable
              multiple
              placeholder="请选择ESG文本指标"
              size="medium"
              @change="changeIndexSelect"
            >
              <el-checkbox v-model="isAllIndexSelected" style="margin-left: 2%;" @change='selectAllIndex'>全选
              </el-checkbox>
              <el-option
                v-for="item in textualIndexOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button size="medium" style="margin-right: 0.8rem; " type="primary" @click="ensureFilter"><i
              class="el-icon-search"></i>&nbsp;确认筛选
            </el-button>
            <el-button size="medium" style="float: right" type="info" @click="goToCombination"><i
              class="el-icon-setting"></i>&nbsp;管理组合
            </el-button>
          </el-col>
        </el-row>
        <!--        <div style="float: left; width: 15%; margin-left: -0.5rem; ">-->
        <!--          <p style="margin: 0 auto; padding-bottom: 1rem; font-size: 1.4rem; text-align: left">筛选器</p>-->
        <!--          <el-select-->
        <!--            v-model="selectStruIndex"-->
        <!--            clearable filterable multiple collapse-tags-->
        <!--            placeholder="ESG结构化数据"-->
        <!--            size="medium">-->
        <!--            <el-option-->
        <!--              v-for="item in struIndexOption"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--          <el-select-->
        <!--            v-model="selectCombination"-->
        <!--            clearable filterable-->
        <!--            placeholder="请选择组合"-->
        <!--            size="medium"-->
        <!--            style="margin-top: 1rem;">-->
        <!--            <el-option-->
        <!--              v-for="item in combinationOptions"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="parseInt(item.value) - 1">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </div>-->

        <el-row :gutter="0" style="width: 80%; margin: 0 auto; text-align: center;">
          <el-col :span="16" style="text-align: left">
            <h3>最新技术指标</h3>
            <el-table
              :data="indexPointers"
              empty-text="请指定您的投资策略"
              :row-style="{height:'30px'}"
              :cell-style="{padding:'0px'}"
              style="margin-top: 0.5rem; margin-bottom: 0.8rem; font-size: 18px;">
              <el-table-column
                :formatter="formatNum"
                align="center"
                label="VaR"
                prop="vaR"></el-table-column>
              <el-table-column
                :formatter="formatNum"
                align="center"
                label="波动率"
                prop="volatility"></el-table-column>
              <el-table-column
                :formatter="formatNum"
                align="center"
                label="最大回撤"
                prop="maxDrawdown"></el-table-column>
              <el-table-column
                :formatter="formatNum"
                align="center"
                label="Sharpe指数"
                prop="sharpe"></el-table-column>
              <el-table-column
                :formatter="formatNum"
                align="center"
                label="Calmar比率"
                prop="calmar"></el-table-column>
            </el-table>

            <h3>回测曲线</h3>

            <!--            <div style="float: right">-->
            <!--              <el-button size="medium" type="primary" style="margin-top: 1rem; margin-right: 0.8rem; " @click="doBackTest"><i-->
            <!--                class="el-icon-search"></i>&nbsp;确认回测-->
            <!--              </el-button>-->

            <!--            </div>-->

            <div style="background-color: white; padding: 1rem">
              <div v-if="earliestDate!=null" style="margin-bottom: 1rem">&nbsp;最大区间：{{ earliestDate }}<i
                class="el-icon-right"></i>{{ latestDate }}
              </div>

              <div v-show="!operationMode">
                <el-skeleton :rows="4" animated/>
                <h3 style="text-align: center">请指定您的投资策略</h3>
                <el-skeleton :rows="4" animated/>
              </div>

              <div v-show="operationMode" style="text-align: left;">
                <div style="text-align: left;display:flex;flex-wrap: wrap;justify-content: space-between">
                  <div style="flex: 1;min-width: 300px">
                    <el-date-picker
                      v-model="startDate"
                      placeholder="选择开始日期"
                      style="min-width: 150px;"
                      type="month"
                      value-format="yyyy-MM"
                      @change="ensureFilter">
                    </el-date-picker>
                    <i class="el-icon-right" style="margin-right: 0.5rem; margin-left: 0.5rem"></i>
                    <el-date-picker
                      v-model="endDate"
                      placeholder="选择结束日期"
                      style="min-width: 150px;"
                      type="month"
                      value-format="yyyy-MM"
                      @change="ensureFilter">
                    </el-date-picker>

                  </div>
                  <div style="flex: 1;min-width: 400px;display:flex;align-items: center;flex-wrap: wrap;">
                    <span style="flex: 1;min-width: 85px; text-align: right; padding-right: 1rem">组合名称 </span>
                    <el-input
                      v-model="combinationName"
                      clearable
                      maxlength="10"
                      placeholder="请输入名称"
                      prefix-icon="el-icon-edit"
                      show-word-limit
                      style="min-width: 240px;flex: 1;"
                      type="text"
                    >
                    </el-input>
                    <el-button size="medium" style="flex: 1; width: fit-content; margin-left: 0.5rem" type="success"
                               @click="savePortfolio"><i
                      class="el-icon-circle-plus-outline"></i>&nbsp;保存组合
                    </el-button>
                  </div>
                </div>
                <div id="chartMain" ref="chartMain"></div>
              </div>
            </div>
          </el-col>

          <el-col :span="8">
            <h3 style="text-align: left; margin-left: 5%;">最新前十重仓股</h3>
            <el-table
              ref="multipleTable"
              :data="companiesInfo"
              empty-text="请指定您的投资策略"
              highlight-current-row
              row-key="name"
              stripe
              style="width: 95%; margin-left: 5%; padding: 0.5rem"
              @cell-click="handleCompanyClick"
            >
              <el-table-column
                align="center"
                label="证券代码"
                min-width="90"
                prop="code"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="证券名称"
                min-width="100"
                prop="name"
              >
                <template v-slot="scope">
                  <el-tooltip content="点击查看公司详情" effect="dark" placement="right">
                    <el-link type="primary">{{ scope.row.name }}<i class="el-icon-view el-icon--right"></i></el-link>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="行业分类"
                min-width="100"
                prop="industry"
              >
                <template v-slot="scope">
                    <span>
                    <i
                      :class="`el-icon--right ${icon_class[Object.values(industryNames).indexOf(scope.row.industry)]}`"></i>
                    {{ scope.row.industry }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="权重"
                min-width="100"
                prop="weight"
              >
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-drawer
          ref="drawer1"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            ref="drawer1iframe"
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com/guide/userguide.html#esg%E6%8A%95%E8%B5%84%E7%B1%BB"
            style="width: 100%; border: hidden"
            title="使用说明-ESG投资"
          >
          </iframe>
        </el-drawer>
      </el-main>

    </el-container>
    <FooterLayoutSlim></FooterLayoutSlim>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'
import * as echarts from 'echarts'
import {addNewComb, calcv1, filterByML, filterByTail, filterStock, getCombInfo} from '@/api/combination'
import {formatYYYYMM, latestMonth, latestYear} from '@/utils'

export default {
  name: 'invest',
  inject: ['reload'],
  components: {
    FooterLayoutSlim,
    HeaderLayout
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      earliestDate: null,
      latestDate: null,
      indexPointer: {
        pointer1: '',
        pointer2: '',
        pointer3: '',
        pointer4: '',
        pointer5: ''
      },
      indexPointers: [],
      isAllSelected: false,
      isAllIndexSelected: false,
      companiesInfo: [],
      selectModel: '',
      selectTextualIndex: [],
      selectStruIndex: [],
      selectIndustry: [],
      industryModify: [],
      selectCombination: [],
      combinationOptions: [],
      combCompanies: [],
      combCompaniesData: {},
      combinationsInfo: [],
      operationMode: false,
      model: [
        {
          value: 'esg',
          label: '使用ESG评分的单指标选股'
        },
        {
          value: 'ml',
          label: '加入ESG评分的多指标选股'
        },
        {
          value: 'tail',
          label: '基于ESG评分尾部剔除的多指标选股'
        }
      ],
      industryOptions: [
        {
          value: 'agriculture',
          label: '农林牧渔'
        }, {
          value: 'chemicals',
          label: '基础化工'
        }, {
          value: 'steel',
          label: '钢铁'
        }, {
          value: 'nonferrous_metal',
          label: '有色金属'
        }, {
          value: 'electronics',
          label: '电子'
        }, {
          value: 'cars',
          label: '汽车'
        }, {
          value: 'household_ele',
          label: '家用电器'
        }, {
          value: 'foods',
          label: '食品饮料'
        }, {
          value: 'clothes',
          label: '纺织服饰'
        }, {
          value: 'light_m',
          label: '轻工制造'
        }, {
          value: 'medicine',
          label: '医药生物'
        }, {
          value: 'utilities',
          label: '公用事业'
        }, {
          value: 'transportation',
          label: '交通运输'
        }, {
          value: 'real_estate',
          label: '房地产'
        }, {
          value: 'commercial_retail',
          label: '商贸零售'
        }, {
          value: 'social_service',
          label: '社会服务'
        }, {
          value: 'banking',
          label: '银行'
        }, {
          value: 'nobank_finance',
          label: '非银金融'
        }, {
          value: 'comprehensive',
          label: '综合'
        }, {
          value: 'building_mat',
          label: '建筑材料'
        }, {
          value: 'building_dec',
          label: '建筑装饰'
        }, {
          value: 'power',
          label: '电力设备'
        }, {
          value: 'mechanical',
          label: '机械设备'
        }, {
          value: 'defense',
          label: '国防军工'
        }, {
          value: 'cs',
          label: '计算机'
        }, {
          value: 'media',
          label: '传媒'
        }, {
          value: 'communication',
          label: '通信'
        }, {
          value: 'coal',
          label: '煤炭'
        }, {
          value: 'petroleum',
          label: '石油石化'
        }, {
          value: 'e_protection',
          label: '环保'
        }, {
          value: 'beauty_care',
          label: '美容护理'
        }],
      industryNames: {
        agriculture: '农林牧渔',
        chemicals: '基础化工',
        steel: '钢铁',
        nonferrous_metal: '有色金属',
        electronics: '电子',
        cars: '汽车',
        household_ele: '家用电器',
        foods: '食品饮料',
        clothes: '纺织服饰',
        light_m: '轻工制造',
        medicine: '医药生物',
        utilities: '公用事业',
        transportation: '交通运输',
        real_estate: '房地产',
        commercial_retail: '商贸零售',
        social_service: '社会服务',
        banking: '银行',
        nobank_finance: '非银金融',
        comprehensive: '综合',
        building_mat: '建筑材料',
        building_dec: '建筑装饰',
        power: '电力设备',
        machanical: '机械设备',
        defense: '国防军工',
        cs: '计算机',
        media: '传媒',
        communication: '通信',
        coal: '煤炭',
        petroleum: '石油石化',
        e_protection: '环保',
        beauty_care: '美容护理'
      },
      textualIndexOptions: [
        {
          value: 'csr_e',
          label: 'CSR报告E评分'
        }, {
          value: 'csr_s',
          label: 'CSR报告S评分'
        }, {
          value: 'csr_g',
          label: 'CSR报告G评分'
        }, {
          value: 'qa_e',
          label: '调研问答E评分'
        }, {
          value: 'qa_s',
          label: '调研问答S评分'
        }, {
          value: 'qa_g',
          label: '调研问答G评分'
        }, {
          value: 'news_e',
          label: '公司新闻E评分'
        }, {
          value: 'news_s',
          label: '公司新闻S评分'
        }, {
          value: 'news_g',
          label: '公司新闻G评分'
        }, {
          value: 'ana_e',
          label: '分析师报告E评分'
        }, {
          value: 'ana_s',
          label: '分析师报告S评分'
        }, {
          value: 'ana_g',
          label: '分析师报告G评分'
        }
      ],
      struIndexOption: [
        {
          value: 'stru_e',
          label: 'E评分'
        }, {
          value: 'stru_s',
          label: 'S评分'
        }, {
          value: 'stru_g',
          label: 'G评分'
        }
      ],
      icon_class: ['el-icon-grape', 'el-icon-magic-stick', 'el-icon-attract', 'el-icon-medal', 'el-icon-cpu', 'el-icon-truck',
        'el-icon-refrigerator', 'el-icon-ice-drink', 'el-icon-watch-1', 'el-icon-toilet-paper', 'el-icon-first-aid-kit',
        'el-icon-help', 'el-icon-box', 'el-icon-office-building', 'el-icon-goods', 'el-icon-service', 'el-icon-bank-card',
        'el-icon-coin', 'el-icon-connection', 'el-icon-brush', 'el-icon-scissors', 'el-icon-s-opportunity', 'el-icon-setting',
        'el-icon-aim', 'el-icon-monitor', 'el-icon-mic', 'el-icon-phone-outline', 'el-icon-discover', 'el-icon-odometer',
        'el-icon-sunny', 'el-icon-star-off'],
      combinationName: ''
    }
  },
  methods: {
    handleDrawerClose(done) {
      this.$refs.drawer1.visible = false
      this.$refs.drawer2.visible = false
    },
    handleCompanyClick(row, column, cell, event) {
      if (column.label === '证券名称') {
        // 跳转到对应公司
        this.$router.push({
          path: '/rank/company',
          query: {
            code: row.code,
            year: latestYear,
            month: latestMonth
          }
        })
      }
    },
    selectAll() {
      this.selectIndustry = []
      if (this.isAllSelected) {
        this.industryOptions.map((item) => {
          this.selectIndustry.push(item.value)
        })
      } else {
        this.selectIndustry = []
      }
    },
    selectAllIndex() {
      this.selectTextualIndex = []
      if (this.isAllIndexSelected) {
        this.textualIndexOptions.map((item) => {
          this.selectTextualIndex.push(item.value)
        })
      } else {
        this.selectTextualIndex = []
      }
    },
    changeModel(val) {
      console.log(this.selectModel)
    },
    changeSelect(val) {
      this.isAllSelected = val.length === this.industryOptions.length
    },
    changeIndexSelect(val) {
      this.isAllIndexSelected = val.length === this.textualIndexOptions.length
    },
    loadAllSelfComb() {
      // 获取该用户全部自选组合用以左侧筛选及组合内所有涉及到的公司
      this.combinationOptions = []
      this.combCompanies = []
      this.combCompaniesData = {}
      this.combinationsInfo = []

      getCombInfo(this.userId).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          const tmp = res.data[i].portfolio.map(item => {
            return item.code
          }).join(',').split(',')
          this.combinationOptions.push({
            label: res.data[i].name,
            value: i + 1 + '',
            companies: tmp
          })
          tmp.forEach(row => {
            if (!this.combCompanies.includes(row)) this.combCompanies.push(row)
          })
        }
        this.combinationName = '自选组合' + (this.combinationOptions.length + 1)
      })
    },
    goToCombination() {
      this.$router.push('/combination')
    },
    ensureFilter() {
      if (this.earliestDate != null) {
        if (this.startDate < this.earliestDate) {
          this.$message.warning("开始时间不能早于最早时间！")
        }
        if (this.endDate > this.latestDate) {
          this.$message.warning("结束时间不能早于最新时间！")
        }
      }
      if (this.selectModel === 'esg') {
        this.FilterNormal();
      } else if (this.selectModel === 'ml') {
        this.FilterML();
      } else if (this.selectModel === 'tail') {
        this.FilterTail();
      } else {
        this.$message.warning("请先选择您的投资策略！")
      }
    },
    compare(p) {
      return function (m, n) {
        const a = m[p]
        const b = n[p]
        return b - a
      }
    },
    FilterNormal() {
      if (this.selectTextualIndex.length * this.selectIndustry.length === 0) {
        this.$message.info('请确认选择行业及ESG文本指标！')
        return
      }
      this.operationMode = true
      // 依据左侧行业筛选数据
      // 依据左侧组合筛选数据
      const industryList = []
      for (let i = 0; i < 31; i++) {
        let flag = false
        for (const ind in this.selectIndustry) {
          if (this.industryOptions[i].value === this.selectIndustry[ind]) {
            flag = true
            break
          }
        }
        industryList.push(flag)
      }
      const indexList = []
      for (let i = 0; i < 12; i++) {
        let flag = false
        for (const ind in this.selectTextualIndex) {
          if (this.textualIndexOptions[i].value === this.selectTextualIndex[ind]) {
            flag = true
            break
          }
        }
        indexList.push(flag)
      }
      for (let i = 0; i < 3; i++) {
        let flag = false
        for (const ind in this.selectStruIndex) {
          if (this.struIndexOption[i].value === this.selectStruIndex[ind]) {
            flag = true
            break
          }
        }
        indexList.push(flag)
      }
      filterStock(indexList, industryList).then(res => {
        const companyList = res.data.companyinfo
        const tmp = []
        for (let i = 0; i < companyList.length; i++) {
          tmp.push({
            code: companyList[i].companyCode,
            name: companyList[i].name,
            industry: this.industryNames[companyList[i].industry],
            weight: ((Math.round(companyList[i].weight * 10000)) / 100).toFixed(2),
            stocknum: Math.round(companyList[i].weight * 10000)
          })
        }
        tmp.sort(this.compare('weight'))
        let limit = tmp.length < 10 ? tmp.length : 10
        for (let i = 0; i < limit; i++) {
          tmp[i].weight = tmp[i].weight + "%"
        }
        console.log(tmp)
        this.companiesInfo = tmp

        this.series = res.data.backTest
        this.time = []
        this.values = []

        const startDate = this.startDate ? this.startDate : null
        const endDate = this.endDate ? this.endDate : null

        this.series.forEach(info => {
          const date = info.yyyyMm
          const yyyy = date.slice(0, 4)
          const mm = date.slice(4, 6)
          const curDate = yyyy + '-' + mm
          if ((startDate === null || curDate >= startDate) && (endDate === null || curDate <= endDate)) {
            this.time.push(curDate)
            this.values.push(info.indexValue.toFixed(4))
          }
        })
        this.startDate = this.time[0]
        this.endDate = this.time[this.time.length - 1]

        const earliestDate = this.series[0].yyyyMm
        const earliestyyyy = earliestDate.slice(0, 4)
        const earliestmm = earliestDate.slice(4, 6)
        this.earliestDate = earliestyyyy + '-' + earliestmm

        const latestDate = this.series[this.series.length - 1].yyyyMm
        const latestyyyy = latestDate.slice(0, 4)
        const latestmm = latestDate.slice(4, 6)
        this.latestDate = latestyyyy + '-' + latestmm

        this.indexPointer.pointer1 = res.data.vaR
        this.indexPointer.pointer2 = res.data.volatility
        this.indexPointer.pointer3 = res.data.maxDrawdown
        this.indexPointer.pointer4 = res.data.sharpe
        this.indexPointer.pointer5 = res.data.calmar
        this.indexPointers = []
        this.indexPointers.push(res.data)
        this.drawMainChart()
      })
    },
    FilterML() {
      this.operationMode = true
      filterByML().then(res => {

        const companyList = res.data.companyinfo
        const tmp = []
        for (let i = 0; i < companyList.length; i++) {
          tmp.push({
            code: companyList[i].companyCode,
            name: companyList[i].name,
            industry: this.industryNames[companyList[i].industry],
            weight: ((Math.round(companyList[i].weight * 10000)) / 100).toFixed(2),
            stocknum: Math.round(companyList[i].weight * 10000)
          })
        }
        tmp.sort(this.compare('weight'))
        let limit = tmp.length < 10 ? tmp.length : 10
        for (let i = 0; i < limit; i++) {
          tmp[i].weight = tmp[i].weight + "%"
        }
        console.log(tmp)
        this.companiesInfo = tmp

        this.series = res.data.backTest
        this.time = []
        this.values = []

        console.log(this.startDate)

        const startDate = this.startDate ? this.startDate : null
        const endDate = this.endDate ? this.endDate : null

        this.series.forEach(info => {
          const date = info.yyyyMm
          const yyyy = date.slice(0, 4)
          const mm = date.slice(4, 6)
          const curDate = yyyy + '-' + mm
          if ((startDate === null || curDate >= startDate) && (endDate === null || curDate <= endDate)) {
            this.time.push(curDate)
            this.values.push(info.indexValue.toFixed(4))
          }
        })
        this.startDate = this.time[0]
        this.endDate = this.time[this.time.length - 1]

        const earliestDate = this.series[0].yyyyMm
        const earliestyyyy = earliestDate.slice(0, 4)
        const earliestmm = earliestDate.slice(4, 6)
        this.earliestDate = earliestyyyy + '-' + earliestmm

        const latestDate = this.series[this.series.length - 1].yyyyMm
        const latestyyyy = latestDate.slice(0, 4)
        const latestmm = latestDate.slice(4, 6)
        this.latestDate = latestyyyy + '-' + latestmm

        this.indexPointer.pointer1 = res.data.vaR
        this.indexPointer.pointer2 = res.data.volatility
        this.indexPointer.pointer3 = res.data.maxDrawdown
        this.indexPointer.pointer4 = res.data.sharpe
        this.indexPointer.pointer5 = res.data.calmar
        this.indexPointers = []
        this.indexPointers.push(res.data)
        this.drawMainChart()
        console.log(res.data)
      })
    },
    FilterTail() {
      this.operationMode = true
      filterByTail().then(res => {
        const companyList = res.data.companyinfo
        const tmp = []
        for (let i = 0; i < companyList.length; i++) {
          tmp.push({
            code: companyList[i].companyCode,
            name: companyList[i].name,
            industry: this.industryNames[companyList[i].industry],
            weight: ((Math.round(companyList[i].weight * 10000)) / 100).toFixed(2),
            stocknum: Math.round(companyList[i].weight * 10000)
          })
        }
        tmp.sort(this.compare('weight'))
        let limit = tmp.length < 10 ? tmp.length : 10
        for (let i = 0; i < limit; i++) {
          tmp[i].weight = tmp[i].weight + "%"
        }
        console.log(tmp)
        this.companiesInfo = tmp

        this.series = res.data.backTest
        this.time = []
        this.values = []

        const startDate = this.startDate ? this.startDate : null
        const endDate = this.endDate ? this.endDate : null

        this.series.forEach(info => {
          const date = info.yyyyMm
          const yyyy = date.slice(0, 4)
          const mm = date.slice(4, 6)
          const curDate = yyyy + '-' + mm
          if ((startDate === null || curDate >= startDate) && (endDate === null || curDate <= endDate)) {
            this.time.push(curDate)
            this.values.push(info.indexValue.toFixed(4))
          }
        })
        this.startDate = this.time[0]
        this.endDate = this.time[this.time.length - 1]

        const earliestDate = this.series[0].yyyyMm
        const earliestyyyy = earliestDate.slice(0, 4)
        const earliestmm = earliestDate.slice(4, 6)
        this.earliestDate = earliestyyyy + '-' + earliestmm

        const latestDate = this.series[this.series.length - 1].yyyyMm
        const latestyyyy = latestDate.slice(0, 4)
        const latestmm = latestDate.slice(4, 6)
        this.latestDate = latestyyyy + '-' + latestmm

        this.indexPointer.pointer1 = res.data.vaR
        this.indexPointer.pointer2 = res.data.volatility
        this.indexPointer.pointer3 = res.data.maxDrawdown
        this.indexPointer.pointer4 = res.data.sharpe
        this.indexPointer.pointer5 = res.data.calmar
        this.indexPointers = []
        this.indexPointers.push(res.data)
        this.drawMainChart()
        console.log(res.data)
      })
    },
    savePortfolio() {
      if (this.companiesInfo.length !== 0) {
        const tmp = []
        this.companiesInfo.forEach(company => {
          tmp.push({
            code: company.code,
            quantity: company.stocknum
          })
        })
        if (this.combinationName.length < 1) {
          this.$message.warning("组合名不能为空")
          return
        }

        addNewComb(this.userId, tmp, this.combinationName).then(res => {
          if (res.code !== 0) {
            this.$message({
              message: res.msg,
              type: 'danger',
              center: true,
              iconClass: 'el-icon-error'
            })
          } else {
            this.$message({
              type: 'success',
              message: '新建成功!'
            })
            this.loadAllSelfComb()
          }
        })
      }
    },
    doBackTest() {
      if (this.selectCombination.length === 0) {
        this.$message.error('请选择组合')
      } else {
        calcv1(this.userId, this.combinationOptions[this.selectCombination].value).then(res => {
          this.series = res.data
          this.time = []
          this.values = []
          let prev = null

          const startDate = formatYYYYMM(this.startDate)
          const endDate = formatYYYYMM(this.endDate)

          for (const date in this.series) {
            const dateList = date.split('-')
            if ((startDate === null || date > startDate) && (endDate === null || date < endDate)) {
              if (dateList[1] !== prev) {
                this.time.push(date)
                this.values.push(this.series[date])
                prev = dateList[1]
              }
            }
          }
          const tmp = [1]
          for (let i = 1; i < this.values.length; i++) {
            let v = this.values[i] / this.values[i - 1]
            tmp.push(v.toFixed(4))
          }
          this.values = tmp
          this.drawMainChart()
        })
      }
    },
    drawMainChart() {
      if (this.chartMain != null) {
        this.chartMain.dispose()
      }
      // console.log(this.values)
      this.chartMain = echarts.init(this.$refs.chartMain)
      let maxIndex = parseFloat(Math.max.apply(null, this.values).toFixed(5))
      let minIndex = parseFloat(Math.min.apply(null, this.values).toFixed(5))
      let intervalIndex = parseFloat(((maxIndex - minIndex) / 5).toFixed(5))
      maxIndex = parseFloat((maxIndex + intervalIndex).toFixed(1))
      minIndex = Math.max(0, parseFloat((minIndex - intervalIndex).toFixed(1)))
      while (minIndex > 1) minIndex = (minIndex - intervalIndex).toFixed(1)
      intervalIndex = parseFloat(((maxIndex - minIndex) / 4).toFixed(1))
      const optionMain = {
        tooltip: {
          trigger: 'axis'
        },
        visualMap: [
          {
            show: false,
            type: 'continuous',
            color: ['#b83840', '#ee3f4d', '#f89895', '#95caea', '#2c86e1'],
            min: Math.min(...this.values),
            max: Math.max(...this.values)
          }
        ],
        legend: {
          data: ['回测结果']
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            data: this.time,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
            },
          }
        ],
        yAxis: [
          {
            max: maxIndex,
            min: minIndex,
            interval: intervalIndex,
            type: 'value',
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '回测结果',
            type: 'line',
            data: this.values,
            areaStyle: {
              color: "#309FEA",
              opacity: 0.06,
            },
            markLine: {
              symbol: 'none',
              silent: true,
              lineStyle: {
                color: '#0101f9',
                type: 'dotted'
              },
              data: [{
                yAxis: 1
              }],
              label: {
                formatter: '1.0'
              },
            },
          },

        ]
      }
      this.chartMain.setOption(optionMain)
      var chartMain = this.chartMain
      // window.addEventListener('resize', function () {
      //   // chartMain.resize()
      // })
    },
    formatNum(row, column, cellValue) {
      let num = cellValue
      num = parseFloat(num)
      num = Math.round(num * Math.pow(10, 4)) / Math.pow(10, 4)
      num = Number(num).toFixed(4)
      return num + ''
    }
  },
  mounted() {
    // this.loadAllSelfComb()
  },
  created() {
    if (localStorage.getItem('store')) {
      console.log('has token')
      this.$store.commit('replace', JSON.parse(localStorage.getItem('store')))
    }
    console.log(JSON.parse(localStorage.getItem('store')))

    this.userId = this.$store.state.userID || 1
    if (this.userId <= 0) this.userId = 1
  }
}
</script>

<style>

#chartMain {
  width: 100%;
  height: 500px;
  margin: 0 auto;
}

.el-header {
  background-color: #34495E;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

table th,
table td {
  border-bottom: none !important;
}

.el-table::before {
  /* 去除下边框 */
  height: 0;
}

.title {
  background-color: #E9EEF3;
  margin: 0;
  font-size: 25px;
  line-height: 1rem;
  text-align: left;
  display: inline-block;
  cursor: pointer;
}
</style>
